import store from "../store";
import api from "@/api.js";
import { success, fail } from "./toast.js";
import { createPopper } from "@popperjs/core";
import {
  SET_CART_AMOUNT,
  SET_CART_ITEMS,
  SET_IS_LOADING_CART,
} from "@/modules/cart/store/index.js";
import { SERVER_VALUES_MAP } from "./constants";

// TODO: Refactor cart methods to services
export async function addToCart(payload) {
  try {
    await api.post("cart/add", payload);

    getCartItems();

    success("Item added to cart");
  } catch (e) {
    fail(e.response.data.message);
  }
}

export async function addSaveProduct(payload) {
  if (window.sessionStorage.getItem("user") !== "authenticated") {
    store.state.AccessState = 1;
    return;
  }

  try {
    const response = await api.post(`/products/save/${payload}`);

    success("Saved item successfully");

    store.commit("setSaveItem", payload, response.data.data);
  } catch (e) {
    fail(e.response.data.message);
  }
}

export async function getCartItems() {
  store.commit(SET_IS_LOADING_CART, true);

  try {
    const res = await api.get("cart/items");

    store.commit(SET_CART_ITEMS, res.data.data.cart_items);
    store.commit(SET_CART_AMOUNT, res.data.data.total_amount_to_be_paid);

    return res.data.data;
  } catch (err) {
    fail("An error occurred while fetching cart!");
  } finally {
    store.commit(SET_IS_LOADING_CART, false);
  }
}

export async function upsertCartItemVariant(productId, ...variants) {
  if (!variants.length) return;

  // Get item variants
  const cartItems = store.state.cartModule.items || {};
  const itemVariants = {};

  for (let id in cartItems) {
    if (id.includes(productId)) {
      itemVariants[id] = cartItems[id];
    }
  }

  // Check item exists
  variants.forEach((variant) => {
    itemVariants[variant.id] = variant;
  });

  const variantsList = Object.values(itemVariants).filter(
    (item) => item.quantity
  );

  if (!variantsList.length) {
    deleteCartItem(variants[0].uuid);

    return;
  }

  const payload = { variant: variantsList };
  store.commit(SET_IS_LOADING_CART, true);

  try {
    await api.put(`cart/${variants[0].uuid}/product-quantity`, payload);

    success("Item saved successfully");

    return true;
  } catch (e) {
    fail(e.response?.data?.message || "An error occurred while saving updates");

    return false;
  } finally {
    store.commit(SET_IS_LOADING_CART, false);

    getCartItems();
  }
}

export async function deleteCartItem(itemId, options = {}) {
  const { notify = true } = options;

  store.commit(SET_IS_LOADING_CART, true);

  try {
    await api.delete(`cart/items/${itemId}`);

    if (notify) success("Item deleted successfully!");
  } catch (e) {
    if (notify) fail(e.response.data.message);
  } finally {
    store.commit(SET_IS_LOADING_CART, false);

    getCartItems();
  }
}

export function formatPrice(price, style = "") {
  const options = {
    currency: "NGN",
    style: "currency",
    currencyDisplay: "code",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  if (style) options.style = style;

  const value = new Intl.NumberFormat("en-US", options).format(price);

  return value ? value : price;
}

export function formatNumber(price, toFixed = 2) {
  const options = {
    notation: "standard",
    minimumFractionDigits: toFixed,
    maximumFractionDigits: toFixed,
  };

  return new Intl.NumberFormat("en-US", options).format(price);
}

export function withPopper(dropdownList, component, { width }) {
  dropdownList.style.width = width;

  const popper = createPopper(component.$refs.toggle, dropdownList, {
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -1],
        },
      },
      {
        name: "toggleClass",
        enabled: true,
        phase: "write",
        fn({ state }) {
          component.$el.classList.toggle("drop-up", state.placement === "top");
        },
      },
    ],
  });

  return () => popper.destroy();
}

export function variantsAsCartItems(items) {
  const invalidCartItems = [];

  const variants = items.reduce((accumulator, item) => {
    if (!item?.product?.uuid) {
      invalidCartItems.push(item);

      return accumulator;
    }

    const itemVariants =
      item?.variant?.map((variant) => {
        const itemSnapshot = { ...item, ...variant };
        let id = itemSnapshot.product.uuid;

        if (variant.colors) {
          id = `${id}+colors:${variant.colors}`;
        }

        if (variant.sizes) {
          id = `${id}+sizes:${variant.sizes}`;
        }

        itemSnapshot._id = itemSnapshot.id;
        itemSnapshot.id = id;

        delete itemSnapshot.variant;

        return itemSnapshot;
      }) || [];

    // Backward Compatibility 🤧
    if (!item.variant && item.quantity > 0) {
      const itemSnapshot = { ...item };
      let id = itemSnapshot.product.uuid;

      itemSnapshot._id = itemSnapshot.id;
      itemSnapshot.id = id;

      delete itemSnapshot.variant;

      itemVariants.push(itemSnapshot);
    }

    return [...accumulator, ...itemVariants];
  }, []);

  deleteInvalidCartItems(invalidCartItems);

  return variants;
}

// Function to shuffle array
export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
}

export function insertRandomly(destination = [], items = []) {
  const result = [...destination];

  items.forEach((item) => {
    const randomIndex = Math.floor(Math.random() * (result.length + 1));
    result.splice(randomIndex, 0, item);
  });

  return result;
}

export async function getVisitorsJwt() {
  try {
    const response = await api.post("generate-jwt");

    const data = JSON.stringify(response.data.data);

    window.localStorage.setItem("jwt", data);
  } catch (err) {
    // Log Error
  }
}

export function getServerValue(value) {
  return SERVER_VALUES_MAP[value] || value;
}

export async function deleteInvalidCartItems(items) {
  await Promise.all(
    items.map((item) => {
      return deleteCartItem(item.uuid, {
        notify: false,
      });
    })
  );

  if (items.length) {
    await getCartItems();
  }
}

export function getPromotionDateRange(products) {
  const validProducts = products?.filter(
    (product) =>
      product.promotion_start_date !== null &&
      product.promotion_end_date !== null
  );

  if (!validProducts || !validProducts?.length) return [null, null];

  const earliestStartDate = validProducts.reduce((earliest, product) => {
    const startDate = new Date(product.promotion_start_date);

    return startDate < earliest ? startDate : earliest;
  }, new Date(validProducts[0].promotion_start_date));

  const latestEndDate = validProducts.reduce((latest, product) => {
    const endDate = new Date(product.promotion_end_date);

    return endDate > latest ? endDate : latest;
  }, new Date(validProducts[0].promotion_end_date));

  return [earliestStartDate.toISOString(), latestEndDate.toISOString()];
}

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export function toCamelSpaced(input) {
  return input
    .toLowerCase()
    .replace(/[-_](.)/g, (_, char) => ` ${char.toUpperCase()}`)
    .replace(/^./, (char) => char.toUpperCase());
}

export function generateDeliveryEstimate(courier, isLoading) {
  const { delivery_fee, delivery_date } = courier;

  if ((delivery_fee === null || delivery_fee === undefined) && !isLoading) {
    return "Set your delivery address";
  }

  const formattedFee = formatNumber(delivery_fee);
  const deliveryDateRange = delivery_date
    ?.split(" - ")
    ?.map((date) => new Date(date).toDateString());

  if (deliveryDateRange?.length === 1) {
    return `Your order will be delivered on or before <b style="font-weight: 600">${deliveryDateRange[0]}</b> for <b style="font-weight: 600" class="text-primary">₦${formattedFee}</b>`;
  }

  if (deliveryDateRange?.length === 2) {
    return `Your order will be delivered between <b style="font-weight: 600">${deliveryDateRange[0]}</b> and <b style="font-weight: 600">${deliveryDateRange[1]}</b> for <b style="font-weight: 600" class="text-primary">₦${formattedFee}</b>`;
  }

  return `Your order will be delivered for <b style="font-weight: 600" class="text-primary">₦${formattedFee}</b>`;
}

export function generateCouriersFromPartners(partners) {
  const _partners = partners.reduce((acc, courierQuery) => {
    const courierData = courierQuery;

    if (!courierData) {
      return [
        ...acc,
        {
          isLoading: courierQuery.isLoading,
          isError: courierQuery.isError,
        },
      ];
    }

    let subCouriers = courierData?.couriers ?? [];

    if (typeof subCouriers === "object") {
      subCouriers = Object.values(subCouriers);
    }

    if (!subCouriers?.length) {
      let delivery_date =
        courierData.delivery_date_info?.estimated_delivery_date?.split(" - ");

      if (!delivery_date) {
        delivery_date = courierData.delivery;
      } else {
        delivery_date =
          delivery_date?.length > 1
            ? delivery_date?.[delivery_date?.length - 1]
            : delivery_date?.[0];
      }

      return [
        ...acc,
        {
          ...courierData,
          image:
            courierData.courier_image ||
            "https://res.cloudinary.com/dzkh9cyq4/image/upload/v1721136174/logo-framed-600x600_ishxbp.jpg",
          delivery_fee: courierData.amount,
          isLoading: courierQuery.isLoading,
          isError: courierQuery.isError,
          delivery_date,
          delivery_company: courierData.name,
        },
      ];
    }

    subCouriers = subCouriers.map((subCourier) => {
      return {
        name: subCourier.courier_name,
        label: subCourier.courier_name,
        image: subCourier.courier_image,
        courier_id: subCourier.courier_id,
        delivery_date: subCourier.delivery_eta_time,
        delivery_fee: subCourier.total,
        isSubvendor: true,
        delivery_company: courierData.name,
        parentVendor: {
          name: courierData.name,
          label: courierData.label,
        },
        isLoading: courierQuery.isLoading,
        isError: courierQuery.isError,
      };
    });

    return [...acc, ...subCouriers];
  }, []);

  return _partners;
}

export function debounce(func, delay) {
  let timeout;

  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
}
