// Mutations
export const SET_SMART_COIN_CHARGE = "checkoutModule/SET_SMART_COIN_CHARGE";
export const SET_TRANSACTION_CHARGE = "checkoutModule/SET_TRANSACTION_CHARGE";
export const SET_DELIVERY_ADDRESS_ID = "checkoutModule/SET_DELIVERY_ADDRESS_ID";
export const SET_WALLET_BALANCE = "checkoutModule/SET_WALLET_BALANCE";
export const SET_PAYMENT_MODE = "checkoutModule/SET_PAYMENT_MODE";
export const SET_DELIVERY_FEE = "checkoutModule/SET_DELIVERY_FEE";
export const SET_COURIER_ID = "checkoutModule/SET_COURIER_ID";
export const SET_IS_SMART_COIN_ENABLED =
  "checkoutModule/SET_IS_SMART_COIN_ENABLED";

// Getters
export const TOTAL = "checkoutModule/TOTAL";
export const COURIERS = "checkoutModule/COURIERS";
export const ORDER_CHARGE = "checkoutModule/ORDER_CHARGE";
export const SPEND_AND_SAVE_CHARGE = "checkoutModule/SPEND_AND_SAVE_CHARGE";
export const SELECTED_DELIVERY_ADDRESS =
  "checkoutModule/SELECTED_DELIVERY_ADDRESS";

// Extract Local Module Variable Name
function moduleName(name) {
  return name.replace("checkoutModule/", "");
}

const CheckoutModule = {
  namespaced: true,
  state: {
    isSmartCoinEnabled: false,
    transactionCharge: 0,
    smartCoinCharge: 0,
    walletBalance: 0,
    deliveryFee: 0,
    paymentMode: "card_payment",
    paymentGateway: "paystack",
    deliveryAddressId: null,
    courierId: null,
  },
  getters: {
    [moduleName(TOTAL)]: (state, getters, rootState) => {
      const cartTotal = rootState.cartModule.totalAmount;

      const spendAndSaveCharge = getters[moduleName(SPEND_AND_SAVE_CHARGE)];

      const coinDiscountCharge = state.isSmartCoinEnabled
        ? state.smartCoinCharge
        : 0;

      const transactionCharge =
        state.paymentMode === "card_payment" ? state.transactionCharge : 0;

      const totalCharge =
        cartTotal +
        spendAndSaveCharge +
        coinDiscountCharge +
        transactionCharge +
        state.deliveryFee;

      return totalCharge;
    },
    [moduleName(ORDER_CHARGE)]: (state, getters) => {
      const total = getters[moduleName(TOTAL)];

      const totalCharge = total - state.transactionCharge;

      return totalCharge;
    },
    [moduleName(SPEND_AND_SAVE_CHARGE)]: (state, getters, rootState) => {
      const isSpendAndSaveEnabled = rootState.user?.spend_and_save;
      const cartTotal = rootState.cartModule.totalAmount;

      const spendAndSaveCharge = isSpendAndSaveEnabled
        ? (rootState.user?.spend_and_save_percentage / 100) * cartTotal
        : 0;

      return spendAndSaveCharge;
    },
    [moduleName(SELECTED_DELIVERY_ADDRESS)]: (state, getters, rootState) => {
      const addresses = rootState?.user?.addresses || [];

      const defaultAddress = addresses?.[0];

      const primaryAddress = addresses
        ? addresses?.find?.((address) => address.is_primary)
        : null;

      if (state.deliveryAddressId && addresses.length) {
        const selectedAddress = addresses?.find(
          (address) => address.uuid === state.deliveryAddressId
        );

        return selectedAddress || primaryAddress || defaultAddress;
      }

      return primaryAddress || defaultAddress;
    },
  },
  mutations: {
    [moduleName(SET_SMART_COIN_CHARGE)](state, balance) {
      state.smartCoinCharge = balance * -1;

      if (!state.smartCoinCharge) {
        state.isSmartCoinEnabled = false;
      }
    },
    [moduleName(SET_WALLET_BALANCE)](state, balance) {
      state.walletBalance = balance;
    },
    [moduleName(SET_TRANSACTION_CHARGE)](state, balance) {
      state.transactionCharge = balance;
    },
    [moduleName(SET_IS_SMART_COIN_ENABLED)](state, value) {
      state.isSmartCoinEnabled = value;
    },
    [moduleName(SET_DELIVERY_ADDRESS_ID)](state, value) {
      state.deliveryAddressId = value;
    },
    [moduleName(SET_PAYMENT_MODE)](state, value) {
      if (value === "card_payment") {
        state.paymentMode = "card_payment";
        state.paymentGateway = "paystack";
      } else {
        state.paymentMode = "wallet";
        state.paymentGateway = "";
      }
    },
    [moduleName(SET_COURIER_ID)](state, value) {
      state.courierId = value;
    },
    [moduleName(SET_DELIVERY_FEE)](state, value) {
      state.deliveryFee = value;
    },
  },
};

export default CheckoutModule;
